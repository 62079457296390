import styled from 'styled-components';

const Panel = styled.div`
  height: 100%;
  position: relative;
  width: 95%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export default Panel;
