import './styled/fonts';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { createStandaloneToast } from '@chakra-ui/toast';
// eslint-disable-next-line import/no-unresolved
import { Fonts, PrivateRender, theme as ThemeData } from '@react-ib-mf/style-guide-ui';
import { ButtonChakra as Button } from '@wow/button';
// eslint-disable-next-line import/no-unresolved
import { themeChakra } from '@wow/themes';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import App from './App';

const { ToastContainer } = createStandaloneToast();

const theme = extendTheme({
  ...ThemeData,
  textStyles: {
    ...themeChakra.textStyles,
  },
  components: {
    Button,
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: 2,
      retryDelay: attempt => attempt * 1000,
    },
  },
});

export default function Root() {
  return (
    <PrivateRender mode={process.env.NODE_ENV}>
      <QueryClientProvider client={queryClient}>
        <ChakraProvider resetCSS theme={theme}>
          <Fonts />
          <App />
          <ToastContainer />
        </ChakraProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </PrivateRender>
  );
}
