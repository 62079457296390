import './index.css';

import { Button } from '@chakra-ui/react';
import { BreadcrumbComponent } from '@wow/breadcrumb';
import { NavLink, Outlet } from 'react-router-dom';

import Panel from './styled';

function Home() {
  return (
    <>
      <BreadcrumbComponent
        items={[
          {
            key: 'item1',
            id: 'item1',
            label: 'Inicio',
            link: '',
          },
          {
            key: 'item2',
            id: 'item2',
            label: 'Otros servicios',
            link: '',
          },
          {
            key: 'item3',
            id: 'item3',
            label: 'Interlegajo by TuLegajo.com',
            link: '',
            isCurrentPage: true,
          },
        ]}
      />
      <Panel>
        <div className='img-not-found' />
        <p className='tit-p'>Estás por salir de Interbanking</p>
        <p className='myStyle-p'>Al continuar, serás redirigido a la plataforma TuLegajo.com de titularidad de Minder S.A. </p>

        <NavLink to='https://interlegajo.interbanking.com.ar/login.htm'>
          <Button aria-label='continue-button' className='marginer' variant='primary'>
            Continuar
          </Button>
        </NavLink>

        <Outlet />
      </Panel>
    </>
  );
}

export default Home;
